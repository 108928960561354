<template>
  <div class="legal">
    <div class="container content has-text-justified" v-html="$t('legal')">
    </div>
    <p><router-link to="/privacy_policy">{{ $t('nav.privacy_policy') }}</router-link></p>
    <p><router-link to="/terms">{{ $t('nav.terms') }}</router-link></p>
  </div>
</template>

<script>

export default {
  name: 'legal',
  metaInfo(){
    return {
      link: this.$getAlternates('legal'),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
